export default class StatusUtils {
    static statusToFriendly(status) {
        if (status === "WaitingForUpload") {
            return "Awaiting Garage Upload";
        }
        
        if (status === "Sent") {
            return "Awaiting File Room"
        }
        
        if (status === "ReadyToDownload") {
            return "Ready to Download"
        }
        
        if (status === "Rejected") {
            return "Rejected"
        }
        
        if (status === "WaitingForGarage") {
            return "Waiting for Garage"
        }
        
        return "????";
    }
}