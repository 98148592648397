import * as React from "react";
import expandDown from "../images/expand-down.png";
import expandUp from "../images/expand-up.png";

export default class ExpandyBoi extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            expanded: false
        }
    }

    expandContract = () => {
        if (! this.state.expanded) {
            if (this.props.onExpand) {
                this.props.onExpand()
            }
        }
        
        this.setState({
            expanded: !this.state.expanded
        })
    }
    
    render() {
        return <div className={"expandyContainer" + (this.props.nested ? " expandyContainerNested" : "")}>
            <div className="expandyHeader" onClick={this.expandContract}>
                {this.state.expanded && <img style={{width: 24, height: 24, float: "right"}} src={expandDown} onClick={() => this.setState({expanded: true})} />}
                {!this.state.expanded && <img style={{width: 24, height: 24, float: "right"}} src={expandUp} onClick={() => this.setState({expanded: false})} />}
                <span className="expandyHeaderTitle">{this.props.header}</span>
            </div>
            {this.state.expanded && <div>
                {this.props.children}
            </div>}
        </div>
    }
}