import * as React from "react";

export default class PostAuthLayout extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <div id="postAuthContainer">
                {this.props.children}
            </div>
        )
    }
}