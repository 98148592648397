import * as React from "react"
import clone from "just-clone";
import Auth from "../Auth"
import Client from "../Client"
import { Redirect } from "react-router-dom";
import logo from "../images/header-logo.png";
import ExpandyBoi from "../components/ExpandyBoi";
import { ToastContainer, toast } from "react-toastify";
import DateUtils from "../DateUtils";
import ErrorUtils from "../ErrorUtils";
import StatusUtils from "../StatusUtils";
import TextInput from "../components/TextInput";
import SubmitButton from "../components/SubmitButton";
import Tabs from "../components/Tabs";
import "react-toastify/dist/ReactToastify.css";

export default class AdminPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            sendToLogout: ! Auth.isLoggedIn() || ! Auth.isAdmin(),
            loadingFileRoomStatus: false,
            loadFileRoomStatusError: null,
            fileRoomStatus: null,
            settingFileRoomStatus: false,
            settingFileRoomStatusError: null,
            loadingUsers: false,
            loadingUsersErrorMessage: null,
            users: null,
            disablingUsers: {},
            dialog: null
        }
        
        this.loggedInUser = Auth.getUserDetails()

        this.mapTypes = [
            {id: "4365954b-91c7-49b1-bec6-3554ee8541c2", name: "ADBLUE/SCR OFF"},
            {id: "1c56d795-f297-40ec-b824-c31f54fc18b6", name: "COLD START NOISE"},
            {id: "419c4d36-027c-4ccb-9bcb-eeb1ad058320", name: "EGR OFF"},
            {id: "0a985eb4-0a7a-4e6b-90c4-aa1d9c4f863c", name: "EXHAUST FLAP REMOVAL"},
            {id: "3f81a7a4-9d45-4f02-b2d6-3e1cff2646d7", name: "HOT START"},
            {id: "a8d0568b-e4e0-4605-ad0b-251327721388", name: "O2/LAMBDA OFF"},
            {id: "c496bab1-64ca-4aed-b78b-27b33b10cae7", name: "START STOP DISABLE"},
            {id: "f4bf102c-352d-4dfe-afc3-1e2458b2a71f", name: "DPF/FAP OFF"},
            {id: "ad08ceda-0f43-4bc1-b665-f79e678d36d6", name: "GPF/OPF OFF"},
            {id: "1a349faf-2824-459c-8e35-1a0a4d2d4876", name: "SWIRL FLAPS OFF"},
            {id: "9636a042-141e-4ff9-9b6d-a90327693596", name: "DTC OFF"},
            {id: "c2a45a34-8fb7-45bd-b581-569dfddfc3dd", name: "POP & BANG (PETROL ONLY)"},
            {id: "06c6bc32-6c6f-41bf-aeba-10d7ee012e3c", name: "SPEED LIMIT OFF"},
            {id: "d4d25f63-4c5f-4ebe-9ea6-fbb6c2d4a7de", name: "TQ MONITORING OFF"},
            {id: "fb57e119-ba5c-4444-a3f2-54f3fecca6f0", name: "STAGE 1"},
            {id: "5b688474-df31-4c99-b846-d6a002cc8868", name: "STAGE 2"},
            {id: "b11f7a3b-c971-4ad4-b7ea-c729a9acd856", name: "NO TUNING"},
            {id: "73ca9d46-eab2-4333-97af-8e4c2a806c6c", name: "ECO-MAP"}
        ]
    }

    componentDidMount() {
        this.loadFileRoomStatus()
        this.loadUsers()
        setInterval(this.loadFileRoomStatus, 60000)
    }

    setStateDialogProperty = (property) => {
        return (value) => {
            this.setState(prevState => {
                const nextState = clone(prevState)
                
                nextState.dialog[property] = value
                
                return nextState
            });
        }
    }

    setStateDialogCheckedProperty = (property) => {
        return (e) => {
            this.setState(prevState => {
                const nextState = clone(prevState)

                nextState.dialog[property] = e.target.checked

                return nextState
            });
        }
    }
    
    setStateFileRequest = (userId, fileRequestId, property) => {
        return (value) => {
            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextStateUser = nextState.users.filter(u => u.id === userId)[0]
                const nextStateFileRequest = nextStateUser.fileRequests.filter(fr => fr.id === fileRequestId)[0]
                
                nextStateFileRequest[property] = value

                return nextState
            });
        }
    }
    
    logout = () => this.setState({
        sendToLogout: true
    })
    
    loadFileRoomStatus = async () => {
        if (this.state.loadingFileRoomStatus || this.state.settingFileRoomStatus) return;
        
        this.setState({
            loadingFileRoomStatus: true,
            loadFileRoomStatusError: null
        });

        const result = await Client.getFileRoomStatus();

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingFileRoomStatus: false,
                loadFileRoomStatusError: true
            });

            return;
        }

        this.setState({
            loadingFileRoomStatus: false,
            fileRoomStatus: result.body.status
        });
    }

    setFileRoomStatus = async () => {
        this.setState({
            settingFileRoomStatus: true,
            settingFileRoomStatusError: null
        });

        const setTo = this.state.fileRoomStatus === "Open" ? "Closed" : "Open";
        const result = await Client.setFileRoomStatus(setTo);

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                settingFileRoomStatus: false,
                settingFileRoomStatusError: true
            });

            return;
        }

        this.setState({
            settingFileRoomStatus: false,
            settingFileRoomStatusError: null,
            fileRoomStatus: setTo
        });
    }

    loadUsers = async () => {
        if (this.state.loadingUsers) return;

        this.setState({
            loadingUsers: true,
            loadUsersErrorMessage: null
        });

        const result = await Client.getUsers();

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingUsers: false,
                loadUsersErrorMessage: ErrorUtils.messageFromBodyOrDefault(result, "Something went wrong fetching Users")
            });

            return;
        }

        this.setState({
            loadingUsers: false,
            users: result.body
        });
    }
    
    enableDisableUser = (user) => {
        return async () => {
            this.setState(previousState => {
                const newState = clone(previousState);

                newState.disablingUsers[user.id] = true
                
                return newState
            })
            
            const result = await Client.updateUser({
                id: user.id,
                email: user.email,
                password: null,
                firstName: user.firstName,
                lastName: user.lastName,
                isDisabled: user.disabledAt === null,
                sendEmailNotifications: user.sendEmailNotifications
            });
            
            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(previousState => {
                    const newState = clone(previousState);

                    newState.disablingUsers[user.id] = false

                    return newState
                })

                this.errorToast(ErrorUtils.messageFromBodyOrDefault(result, `Something went wrong trying to ${user.disabledAt !== null ? "enable" : "disable"} the User`));

                return;
            }

            this.setState(previousState => {
                const newState = clone(previousState);

                newState.disablingUsers[user.id] = false
                newState.users.filter(newStateUser => user.id === newStateUser.id)[0].disabledAt = (user.disabledAt === null ? new Date() : null)

                return newState
            })

            this.infoToast(`Succesfully ${user.disabledAt !== null ? "enabled" : "disabled"} the User`)
        }
    }

    infoToast = (message) => {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    
    errorToast = (message) => {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    
    showNewGarageUserDialog = () => this.setState({
        dialog: {
            creatingUser: false,
            type: "newUser",
            title: `Create a new Garage User`,
            newIsAdmin: false,
            newEmail: "",
            newFirstName: "",
            newLastName: "",
            newPassword: ""
        }
    })

    showNewAdminUserDialog = () => this.setState({
        dialog: {
            creatingUser: false,
            type: "newUser",
            title: `Create a new Admin User`,
            newIsAdmin: true,
            newEmail: "",
            newFirstName: "",
            newLastName: "",
            newPassword: "",
            newSendEmailNotifications: true
        }
    })
    
    showManageCreditsDialog = (user) => {
        return async () => {
            this.setState({
                dialog: {
                    loadingHistory: true,
                    user: user,
                    type: "manageCredits",
                    title: `Manage Credits for ${user.firstName} ${user.lastName}`
                }
            })
            
            const result = await Client.getUserCreditHistory(user.id)

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                if (this.state.dialog !== null) {
                    this.setState(prevState => {
                        const nextState = clone(prevState)

                        nextState.dialog.loadingHistory = false
                        nextState.dialog.loadingHistoryError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to load credit history")
                        
                        return nextState
                    })
                }

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                nextState.dialog.loadingHistory = false
                nextState.dialog.history = result.body

                return nextState
            })
        }
    }

    showChangePasswordDialog = (user) => {
        return () => {
            this.setState({
                dialog: {
                    user: user,
                    type: "changePassword",
                    title: `Change Password for ${user.firstName} ${user.lastName}`
                }
            })
        }
    }

    showEditDetailsDialog = (user) => {
        return () => {
            this.setState({
                dialog: {
                    loadingHistory: true,
                    user: user,
                    type: "editDetails",
                    title: `Edit Details for ${user.firstName} ${user.lastName}`,
                    newEmail: user.email,
                    newFirstName: user.firstName,
                    newLastName: user.lastName,
                    newSendEmailNotifications: user.sendEmailNotifications
                }
            })
        }
    }

    hideDialog = () => this.setState({
        dialog: null
    })
    
    addCredits = async (e) => {
        e.preventDefault()

        this.setState(prevState => {
            const nextState = clone(prevState)

            nextState.dialog.addingCredits = true
            nextState.dialog.addingCreditsError = null

            return nextState
        })

        const result = await Client.addCredit({
            userId: this.state.dialog.user.id,
            amount: this.state.dialog.addCreditsAmount,
            reason: this.state.dialog.addCreditsReason
        })

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            if (this.state.dialog !== null) {
                this.setState(prevState => {
                    const nextState = clone(prevState)

                    nextState.dialog.addingCredits = false
                    nextState.dialog.addingCreditsError = "Unable to add credit"

                    return nextState
                })
            }

            return;
        }

        this.setState(prevState => {
            const nextState = clone(prevState)

            nextState.dialog.addingCredits = false
            nextState.dialog.history.unshift(result.body)
            nextState.users.filter(stateUser => stateUser.id === nextState.dialog.user.id)[0].credits += result.body.amount
            nextState.dialog.addCreditsAmount = ""
            nextState.dialog.addCreditsReason = ""
            
            return nextState
        })
    }

    changePassword = async (e) => {
        e.preventDefault()

        this.setState(prevState => {
            const nextState = clone(prevState)

            nextState.dialog.changingPassword = true
            nextState.dialog.changePasswordError = null

            return nextState
        })

        const result = await Client.updateUser({
            id: this.state.dialog.user.id,
            email: this.state.dialog.user.email,
            password: this.state.dialog.newPassword,
            firstName: this.state.dialog.user.firstName,
            lastName: this.state.dialog.user.lastName,
            isDisabled: this.state.dialog.user.disabledAt !== null,
            sendEmailNotifications: this.state.dialog.user.isAdmin ? this.state.dialog.user.sendEmailNotifications : null
        })

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            if (this.state.dialog !== null) {
                this.setState(prevState => {
                    const nextState = clone(prevState)

                    nextState.dialog.changingPassword = false
                    nextState.dialog.changePasswordError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to change password")

                    return nextState
                })
            }

            return;
        }

        this.setState({
            dialog: null
        })
        
        this.infoToast("Succesfully changed password")
    }

    updateDetails = async (e) => {
        e.preventDefault()

        this.setState(prevState => {
            const nextState = clone(prevState)

            nextState.dialog.updatingDetails = true
            nextState.dialog.updateDetailsError = null

            return nextState
        })

        const result = await Client.updateUser({
            id: this.state.dialog.user.id,
            email: this.state.dialog.newEmail,
            password: null,
            firstName: this.state.dialog.newFirstName,
            lastName: this.state.dialog.newLastName,
            isDisabled: this.state.dialog.user.disabledAt !== null,
            sendEmailNotifications: this.state.dialog.user.isAdmin ? this.state.dialog.newSendEmailNotifications : null
        })

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            if (this.state.dialog !== null) {
                this.setState(prevState => {
                    const nextState = clone(prevState)

                    nextState.dialog.updatingDetails = false
                    nextState.dialog.updateDetailsError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to update details")

                    return nextState
                })
            }

            return;
        }

        this.setState(prevState => {
            const nextState = clone(prevState)
            const nextStateUser = nextState.users.filter(stateUser => stateUser.id === nextState.dialog.user.id)[0]

            nextStateUser.email = nextState.dialog.newEmail
            nextStateUser.firstName = nextState.dialog.newFirstName
            nextStateUser.lastName = nextState.dialog.newLastName
            nextStateUser.sendEmailNotifications = nextState.dialog.newSendEmailNotifications
            
            nextState.dialog = null

            return nextState
        })
        
        this.infoToast("Succesfully updated details")
    }

    createUser = async (e) => {
        e.preventDefault()

        this.setState(prevState => {
            const nextState = clone(prevState)

            nextState.dialog.creatingUser = true
            nextState.dialog.createUserError = null

            return nextState
        })

        const result = await Client.createUser({
            email: this.state.dialog.newEmail,
            password: this.state.dialog.newPassword,
            firstName: this.state.dialog.newFirstName,
            lastName: this.state.dialog.newLastName,
            isAdmin: this.state.dialog.newIsAdmin,
            sendEmailNotifications: this.state.dialog.newIsAdmin ? this.state.dialog.newSendEmailNotifications : null,
        })

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            if (this.state.dialog !== null) {
                this.setState(prevState => {
                    const nextState = clone(prevState)

                    nextState.dialog.creatingUser = false
                    nextState.dialog.createUserError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to create User")

                    return nextState
                })
            }

            return;
        }

        this.setState({
            dialog: null
        })

        this.loadUsers()
        
        this.infoToast("Succesfully created user")
    }

    loadFileRequests = async (userId) => {
        this.setState(prevState => {
            const nextState = clone(prevState)

            const nextStateUser = nextState.users.filter(u => u.id === userId)[0]
            nextStateUser.loadingFileRequests = true
            nextStateUser.loadFileRequestsError = null
            nextStateUser.fileRequests = null

            return nextState
        })

        const result = await Client.getFileRequests(userId)

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            console.error("Something went wrong fetching file requests", result)

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextStateUser = nextState.users.filter(u => u.id === userId)[0]
                nextStateUser.loadingFileRequests = false
                nextStateUser.loadFileRequestsError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to load file requests")

                return nextState
            })

            return;
        }

        this.setState(prevState => {
            const nextState = clone(prevState)

            const nextStateUser = nextState.users.filter(u => u.id === userId)[0]
            nextStateUser.loadingFileRequests = false
            nextStateUser.fileRequests = result.body.sort((a, b) => {
                if (a.status === "Completed" && b.status !== "Completed") {
                    return -1
                }

                if (b.status === "Completed" && a.status !== "Completed") {
                    return 1
                }
                
                return new Date(b.requestedAt) - new Date(a.requestedAt)
            })

            return nextState
        })
    }

    postFileRequestComment = (fileRequest) => {
        return async (e) => {
            e.preventDefault()
            
            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]
                
                nextFileRequest.postingComment = true
                nextFileRequest.postCommentError = null
                
                return nextState
            })
            
            const result = await Client.postComment({
                fileRequestId: fileRequest.id,
                comment: fileRequest.newComment
            })

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)

                    const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                    const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.postingComment = false
                    nextFileRequest.postCommentError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to post comment")

                    return nextState
                })

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.comments.push(result.body)
                
                nextFileRequest.postingComment = false
                nextFileRequest.newComment = ""

                return nextState
            })
        }
    }

    rejectFileRequest = (fileRequest) => {
        return async (e) => {
            e.preventDefault()

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.rejecting = true
                nextFileRequest.rejectError = null

                return nextState
            })

            const result = await Client.rejectFileRequest(fileRequest.id)

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)

                    const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                    const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.rejecting = false
                    nextFileRequest.rejectError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to reject File Request")

                    return nextState
                })

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.rejecting = false
                nextFileRequest.status = "Rejected"
                nextUser.nonCompletedRequests -= 1

                return nextState
            })
        }
    }

    awaitGarageFileRequest = (fileRequest) => {
        return async (e) => {
            e.preventDefault()

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.awaitingGarage = true
                nextFileRequest.awaitingGarageError = null

                return nextState
            })

            const result = await Client.awaitingGarageFileRequest(fileRequest.id)

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)

                    const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                    const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.awaitingGarage = false
                    nextFileRequest.awaitingGarageError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to set to awaiting Garage")

                    return nextState
                })

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.awaitingGarage = false
                nextFileRequest.status = "WaitingForGarage"

                return nextState
            })
        }
    }

    markFileRequestCommentSeen = (fileRequest) => {
        return async () => {
            if (fileRequest.markingCommentsSeen) return
            
            if (fileRequest.comments.filter(comment => comment.seenByAdminAt === null).length === 0) return

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.markingCommentsSeen = true

                return nextState
            })

            const result = await Client.markCommentsSeen(fileRequest.id)

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)

                    const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                    const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.markingCommentsSeen = false

                    return nextState
                })
                
                this.errorToast("Unable to mark comments as seen")

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                var reduceBy = 0
                nextFileRequest.comments.forEach(comment => {
                    if (comment.seenByAdminAt === null) {
                        comment.seenByAdminAt = new Date()
                        comment.seenByAdminUserId = this.loggedInUser.userId
                        reduceBy += 1
                    }
                })
                
                nextUser.unseenComments -= reduceBy

                nextFileRequest.markingCommentsSeen = false

                return nextState
            })
        }
    }
    
    onExpandUser = (user) => {
        return () => {
            if (! user.isAdmin && user.fileRequests == null && ! user.loadingFileRequests) {
                this.loadFileRequests(user.id)
            }
        }
    }
    
    render() {
        if (this.state.sendToLogout) return <Redirect to="logout" />

        return <>
            {this.renderHeader()}
            <div id="mainContentContainer">
                {this.renderUsers()}
            </div>
            {this.state.dialog && <>
                <div id="dialogBackdrop" onClick={this.hideDialog} />
                <div id="dialog">
                    <div className="buttonStrip dialogButtonStrip">
                        <span id="dialogHeader">{this.state.dialog.title}</span>
                        <button onClick={this.hideDialog}>Close</button>
                    </div>
                    {this.state.dialog.type === "newUser" && this.renderNewUserDialog()}
                    {this.state.dialog.type === "manageCredits" && this.renderManageCreditsDialog()}
                    {this.state.dialog.type === "changePassword" && this.renderChangePasswordDialog()}
                    {this.state.dialog.type === "editDetails" && this.renderEditDetailsDialog()}
                </div>
            </>}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    }
    
    renderHeader = () => {
        return <div id="header">
            <img id="headerLogo" src={logo} />
            <span>
                {this.renderFileRoomStatus()}
                <button onClick={this.logout} className="logoutButton">Logout</button>
            </span>
        </div>
    }
    
    renderFileRoomStatus = () => {
        if (this.state.loadingFileRoomStatus) {
            return <button className="fileRoomLoadingButton">File Room Status: Loading</button>
        }

        if (this.state.settingFileRoomStatus) {
            return <button className="fileRoomLoadingButton">File Room Status: Setting</button>
        }
        
        if (this.state.loadFileRoomStatusError) {
            return <button onClick={this.loadFileRoomStatus} className="fileRoomClosedButton">File Room Status: Error - click to retry</button>
        }
        
        if (this.state.fileRoomStatus === "Open") {
            return <button onClick={this.setFileRoomStatus} className="fileRoomOpenButton">File Room Status: Open</button>
        }

        return <button onClick={this.setFileRoomStatus} className="fileRoomClosedButton">File Room Status: Closed</button>
    }
    
    renderUsers = () => {
        if (this.state.loadingUsers) {
            return <p>Loading Users....</p>
        }
        
        if (this.state.loadUsersErrorMessage) {
            return <p className="errorText">{this.state.loadUsersErrorMessage} <button onClick={this.loadUsers}>Try Again</button></p>
        }

        if (this.state.users) {
            const renderedUsers = this.state.users.map(user => {
                let labels = []
                if (user.isAdmin) {
                    const you = user.userId == this.loggedInUser.userId
                    
                    labels.push(<span className="label blueLabel">Admin {you ? "(You)" : ""}</span>)
                } else {
                    labels.push(<span className="label blueLabel"> {user.credits} Credits</span>)
                    labels.push(<span className={"label" + (user.nonCompletedRequests > 0 ? " orangeLabel": " greenLabel")}> {user.nonCompletedRequests} Outstanding Request(s)</span>)
                    if (user.unseenComments !== 0) {
                        labels.push(<span className="label orangeLabel"> {user.unseenComments} Unseen Comment(s)</span>)
                    }
                }
                
                return <ExpandyBoi onExpand={this.onExpandUser(user)} key={user.id} header={<span>{user.firstName} {user.lastName} {labels}</span>}>
                    {user.isAdmin && this.renderAdminUser(user)}
                    {!user.isAdmin && this.renderGarageUser(user)}
                </ExpandyBoi>
            })
            
            return <>
                <div className="buttonStrip">
                    <span id="welcome">Welcome {this.loggedInUser.firstName} {this.loggedInUser.lastName}</span>
                    <span>
                        <button className="addUserButton" onClick={this.showNewAdminUserDialog}>Add new Admin User</button>
                        <button className="addUserButton" onClick={this.showNewGarageUserDialog}>Add new Garage User</button>
                        <button className="addUserButton" onClick={this.loadUsers}>Refresh</button>
                    </span>
                </div>
                {renderedUsers}
            </>
        }
    }
    
    renderAdminUser = (user) => {
        return <>
            <div className="buttonStrip">
                <span />
                <span className="flexEndy">
                    <button className="userOptionsButton" onClick={this.showEditDetailsDialog(user)}>Edit Details</button>
                    <button className="userOptionsButton" onClick={this.showChangePasswordDialog(user)}>Change Password</button>
                    {user.id != this.loggedInUser.userId && <button className="userOptionsButton" onClick={this.enableDisableUser(user)}>{user.disabledAt !== null ? "Enable" : "Disable"}</button>}
                </span>
            </div>
        </>
    }

    downloadUpload = (fileRequestId, uploadId) => {
        return async () => {
            try {
                await Client.downloadFileRequestUpload(fileRequestId, uploadId)
            } catch (error) {
                this.errorToast("Could not download file")
            }
        }
    }

    downloadDownload = (fileRequestId, downloadId) => {
        return async () => {
            try {
                await Client.downloadFileRequestDownload(fileRequestId, downloadId)
            } catch (error) {
                this.errorToast("Could not download file")
            }
        }
    }
    
    triggerUploadDialog = (fileRequestId) => {
        return (e) => {
            e.preventDefault()
            document.getElementById("upload-file-" + fileRequestId).click()
        }
    }

    onFileSelected = (fileRequest) => {
        return async (event) => {
            event.preventDefault()
            
            if (event.target.files && event.target.files[0]) {
                this.setStateFileRequest(fileRequest.userId, fileRequest.id, "uploadFile")(event.target.files[0])
            }
        }
    }
    
    submitFileUpload = (fileRequest) => {
        return async (e) => {
            e.preventDefault()

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.uploadingFile = true
                nextFileRequest.uploadError = null

                return nextState
            })
            
            const result = await Client.uploadFileRequestDownload(fileRequest.id, fileRequest.uploadFileDescription || "", fileRequest.uploadFile.name, fileRequest.uploadFile);

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)

                    const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                    const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.uploadingFile = false
                    nextFileRequest.uploadError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to upload file")
                    nextFileRequest.uploadFile = null
                    nextFileRequest.uploadFileDescription = ""
                    
                    return nextState
                })
                
                return
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextUser = nextState.users.filter(u => u.id === fileRequest.userId)[0]
                const nextFileRequest = nextUser.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.uploadingFile = false
                nextFileRequest.uploadFile = null
                nextFileRequest.uploadFileDescription = ""
                
                nextFileRequest.downloads.unshift(result.body)

                nextFileRequest.status = "ReadyToDownload"
                nextUser.nonCompletedRequests -= 1

                return nextState
            })
            
            this.infoToast("Succesfully uploaded file")
        }
    }

    renderGarageUser = (user) => {
        return <>
            <div className="buttonStrip">
                <span />
                <span className="flexEndy">
                    <button className="userOptionsButton" onClick={this.showEditDetailsDialog(user)}>Edit Details</button>
                    <button className="userOptionsButton" onClick={this.showChangePasswordDialog(user)}>Change Password</button>
                    <button className="userOptionsButton" onClick={this.showManageCreditsDialog(user)}>Manage Credits</button>
                    {! this.state.disablingUsers[user.id] && <button className="userOptionsButton" onClick={this.enableDisableUser(user)}>{user.disabledAt !== null ? "Enable" : "Disable"}</button>}
                    {this.state.disablingUsers[user.id] && <button className="userOptionsButton">Working on it</button>}
                </span>
            </div>
            {user.loadingFileRequests && <p>Loading File Requests...</p>}
            {user.loadFileRequestsError && <p className="errorText">{user.loadFileRequestsError}</p>}
            {user.fileRequests !== null && user.fileRequests !== undefined && user.fileRequests.length === 0 && <strong>This user has not requested any files yet.</strong>}
            {user.fileRequests && user.fileRequests.map(fileRequest => {
                const labels = []

                if (fileRequest.status === "ReadyToDownload") {
                    labels.push(<span className="label greenLabel">Ready to Download</span>)
                } else {
                    labels.push(<span className="label orangeLabel">{StatusUtils.statusToFriendly(fileRequest.status)}</span>)
                }
                
                const unseenComments = fileRequest.comments.filter(comment => comment.seenByAdminAt === null).length
                
                if (unseenComments > 0) {
                    labels.push(<span className="label orangeLabel">{unseenComments} Unseen Comment(s)</span>)
                }
                
                return <div key={fileRequest.id}>
                    <ExpandyBoi nested={true} key={fileRequest.id} header={<span>{fileRequest.registration} - {fileRequest.yearOfManufacture} {fileRequest.make} {fileRequest.model} {labels}</span>}>
                        <div className="buttonStrip">
                            <span />
                            <span className="flexEndy">
                                {!fileRequest.rejecting && !fileRequest.awaitingGarage && fileRequest.status !== "Rejected" && fileRequest.status !== "ReadyToDownload" && <button className="userOptionsButton" onClick={this.rejectFileRequest(fileRequest)}>Reject</button>}
                                {!fileRequest.rejecting && !fileRequest.awaitingGarage && fileRequest.status !== "Rejected" && fileRequest.status !== "ReadyToDownload" && fileRequest.status !== "WaitingForGarage" && <button className="userOptionsButton" onClick={this.awaitGarageFileRequest(fileRequest)}>Set to Awaiting Garage</button>}
                            </span>
                        </div>
                        <div className="fileRequestInfo">
                            <div>
                                <p><strong>Make:</strong> {fileRequest.make}</p>
                                <p><strong>Model:</strong> {fileRequest.model}</p>
                                <p><strong>Year of Manufacture:</strong> {fileRequest.yearOfManufacture}</p>
                                <p><strong>Engine CC:</strong> {fileRequest.engineCC}</p>
                                <p><strong>Fuel Type:</strong> {fileRequest.fuelType}</p>
                            </div>
                            <div>
                                <p><strong>Transmission Type:</strong> {fileRequest.transmissionType}</p>
                                <p><strong>Stock HP/TQ:</strong> {fileRequest.stockHPTQ}</p>
                                <p><strong>Registration:</strong> {fileRequest.registration}</p>
                                <p><strong>Mileage:</strong> {fileRequest.mileage}</p>
                                <p><strong>Read Type:</strong> {fileRequest.readType}</p>
                            </div>
                            <div>
                                <p><strong>System Used for Read:</strong> {fileRequest.systemUsedForRead}</p>
                                <p><strong>ECU:</strong> {fileRequest.ecu}</p>
                                <p><strong>Modifications:</strong> {fileRequest.modifications}</p>
                                <p><strong>Notes:</strong> {fileRequest.notes}</p>
                            </div>
                        </div>
                        <div>
                            <p><strong>Maps to Apply</strong></p>
                            <ul>
                                {fileRequest.requestMaps.map(rm => <li key={rm.id}>{this.mapTypes.filter(mt => mt.id === rm.mapTypeId)[0].name}</li>)}
                            </ul>
                        </div>
                        <Tabs tabs={[{
                            title: "Uploads from User",
                            content:  <div>
                                {fileRequest.uploads.length === 0 && <p>No uploads</p>}
                                {fileRequest.uploads.map(upload => {
                                    return <div key={upload.id} className="comment">
                                        <strong>{upload.name} @ {DateUtils.dateToFriendly(upload.uploadedAt)}</strong>
                                        <p>{upload.description}</p>
                                        <button className="downloadButton" onClick={this.downloadUpload(fileRequest.id, upload.id)}>Download</button>
                                    </div>
                                })}
                            </div>
                        }, {
                            title: "Uploads from us",
                            content: <div>
                                {fileRequest.downloads.length === 0 && <p>No downloads</p>}
                                {fileRequest.downloads.map(download => {
                                    return <div key={download.id} className="comment">
                                        <strong>{download.name} @ {DateUtils.dateToFriendly(download.uploadedAt)}</strong>
                                        <p>{download.description}</p>
                                        <button className="downloadButton" onClick={this.downloadDownload(fileRequest.id, download.id)}>Download</button>
                                    </div>
                                })}
                                <div className="comment">
                                    <form onSubmit={this.submitFileUpload(fileRequest)}>
                                        <strong>New Upload</strong>
                                        <p>Upload a file for the user to download</p>
                                        <button className="downloadButton" onClick={this.triggerUploadDialog(fileRequest.id)}>Select File</button>
                                        {fileRequest.uploadFile && <em className="uploadFileName">{fileRequest.uploadFile.name}</em>}
                                        <TextInput type="text" title="Description (Optional)" disabled={fileRequest.uploadingFile} value={fileRequest.uploadFileDescription} onChange={this.setStateFileRequest(user.id, fileRequest.id, "uploadFileDescription")} />
                                        <input id={"upload-file-" + fileRequest.id} type="file" style={{display: "none"}} onChange={this.onFileSelected(fileRequest)} />
                                        <SubmitButton errorMessage={fileRequest.uploadError} title="Upload File" disabled={fileRequest.uploadingFile || !fileRequest.uploadFile} />
                                    </form>
                                </div>
                            </div>
                        }, {
                            title: "Comments" + (unseenComments > 0 ? " (" + unseenComments + " Unseen)" : ""),
                            onSelected: this.markFileRequestCommentSeen(fileRequest),
                            content: <div>
                                {fileRequest.comments.length === 0 && <p>No comments</p>}
                                {fileRequest.comments.map(comment => {
                                    return <div key={comment.id} className="comment">
                                        <strong>{comment.userName} @ {DateUtils.dateToFriendly(comment.commentedAt)}</strong>
                                        <p>{comment.comment}</p>
                                    </div>
                                })}
                                <div className="comment">
                                    <form onSubmit={this.postFileRequestComment(fileRequest)}>
                                        <TextInput type="multiline-text" title="New Comment" disabled={fileRequest.postingComment} value={fileRequest.newComment} onChange={this.setStateFileRequest(user.id, fileRequest.id, "newComment")} />
                                        <SubmitButton errorMessage={fileRequest.postCommentError} title="Post Comment" disabled={fileRequest.postingComment} />
                                    </form>
                                </div>
                            </div>
                        }]} />
                    </ExpandyBoi>
                </div>
            })}
        </>
    }
    
    renderManageCreditsDialog = () => {
        return <div>
            {this.renderAddCredits()}
            <div className="creditsHistory">
                <strong>Credits History:</strong>
                {this.renderCreditsHistory()}
            </div>
        </div>
    }
    
    renderAddCredits = () => {
        return <form onSubmit={this.addCredits}>
            <TextInput type="text" title="Amount" disabled={this.state.dialog.addingCredits} value={this.state.dialog.addCreditsAmount} onChange={this.setStateDialogProperty("addCreditsAmount")} />
            <TextInput type="text" title="Reason" disabled={this.state.dialog.addingCredits} value={this.state.dialog.addCreditsReason} onChange={this.setStateDialogProperty("addCreditsReason")} />
            <SubmitButton errorMessage={this.state.dialog.addingCreditsError} title="Add Credits" disabled={this.state.dialog.addingCredits} />
        </form>
    }
    
    renderCreditsHistory = () => {
        if (this.state.dialog.loadingHistory) {
            return <p>Loading...</p>
        }
        
        if (this.state.dialog.loadingHistoryError) {
            return <p className="errorText">{this.state.dialog.loadingHistoryError}</p>
        }
        
        return this.state.dialog.history.map(historyEntry => {
            const amountLabel = (historyEntry.amount > 0 ? "+" : "") + `${historyEntry.amount} Credits`

            return <div key={historyEntry.id} className="historyEntry"><span className="amountLabel">{amountLabel}</span><span className="amountBit">{DateUtils.dateToFriendly(historyEntry.addedAt)} - {historyEntry.reason}</span></div>
        })
    }

    renderChangePasswordDialog = () => {
        return <div>
            <form onSubmit={this.changePassword}>
                <TextInput type="password" title="New Password (at least 8 characters)" disabled={this.state.dialog.changingPassword} value={this.state.dialog.newPassword} onChange={this.setStateDialogProperty("newPassword")} />
                <SubmitButton errorMessage={this.state.dialog.changePasswordError} title="Change Password" disabled={this.state.dialog.changingPassword} />
            </form>
        </div>
    }

    renderEditDetailsDialog = () => {
        return <div>
            <form onSubmit={this.updateDetails}>
                <TextInput type="text" title="Email" disabled={this.state.dialog.updatingDetails} value={this.state.dialog.newEmail} onChange={this.setStateDialogProperty("newEmail")} />
                <TextInput type="text" title="First Name" disabled={this.state.dialog.updatingDetails} value={this.state.dialog.newFirstName} onChange={this.setStateDialogProperty("newFirstName")} />
                <TextInput type="text" title="Last Name" disabled={this.state.dialog.updatingDetails} value={this.state.dialog.newLastName} onChange={this.setStateDialogProperty("newLastName")} />
                {this.state.dialog.user.isAdmin && <div>
                    <input type="checkbox" onChange={this.setStateDialogCheckedProperty("newSendEmailNotifications")} checked={this.state.dialog.newSendEmailNotifications} disabled={this.state.dialog.creatingUser} id="sendEmailNotifications" /><label htmlFor="sendEmailNotifications">Send email notifications to this user about file requests, uploads & comments</label>
                </div>}
                <SubmitButton errorMessage={this.state.dialog.updateDetailsError} title="Update Details" disabled={this.state.dialog.updatingDetails} />
            </form>
        </div>
    }

    renderNewUserDialog = () => {
        return <div>
            <form onSubmit={this.createUser}>
                <TextInput type="text" title="Email" disabled={this.state.dialog.creatingUser} value={this.state.dialog.newEmail} onChange={this.setStateDialogProperty("newEmail")} />
                <TextInput type="text" title="First Name" disabled={this.state.dialog.creatingUser} value={this.state.dialog.newFirstName} onChange={this.setStateDialogProperty("newFirstName")} />
                <TextInput type="text" title="Last Name" disabled={this.state.dialog.creatingUser} value={this.state.dialog.newLastName} onChange={this.setStateDialogProperty("newLastName")} />
                <TextInput type="password" title="Password (at least 8 characters)" disabled={this.state.dialog.creatingUser} value={this.state.dialog.newPassword} onChange={this.setStateDialogProperty("newPassword")} />
                {this.state.dialog.newIsAdmin && <div>
                    <input type="checkbox" onChange={this.setStateDialogCheckedProperty("newSendEmailNotifications")} checked={this.state.dialog.newSendEmailNotifications} disabled={this.state.dialog.creatingUser} id="sendEmailNotifications" /><label htmlFor="sendEmailNotifications">Send email notifications to this user about file requests, uploads & comments</label>
                </div>}
                <SubmitButton errorMessage={this.state.dialog.createUserError} title="Create User" disabled={this.state.dialog.creatingUser} />
            </form>
        </div>
    }
}
