import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import AdminPage from "./pages/AdminPage";
import AppPage from "./pages/AppPage";
import NotFoundPage from "./pages/NotFoundPage";

export const routes = [
    {
        name: "Login",
        path: "/login",
        exact: true,
        preauth: true,
        page: LoginPage
    }, {
        name: "Logout",
        path: "/logout",
        exact: true,
        preauth: false,
        page: LogoutPage
    }, {
        name: "Admin",
        path: "/admin",
        exact: true,
        preauth: false,
        page: AdminPage
    }, {
        name: "App",
        path: "/app",
        exact: true,
        preauth: false,
        page: AppPage
    }, {
        name: "Not Found",
        path: "/not-found",
        exact: true,
        preauth: true,
        page: NotFoundPage
    },
];
