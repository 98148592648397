import * as React from "react";
import logo from "../images/medlicott-motors.webp";

export default class NotFoundPage extends React.Component {
    render() {
        return <div id="loginPageContainer">
            <div id="loginDialog">
                <div>
                    <img src={logo} className="loginLogo" />
                    <p className="errorText">Sorry, we couldn't find the page you requested.  <a href="/login">Go to Login Page</a></p>
                </div>
            </div>
        </div>
    }
}
