import * as React from "react";

export default class DropDownInput extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            active: false
        };
        
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onFocus() {
        this.setState({
            active: true
        });
    }
    
    onBlur() {
        this.setState({
            active: false
        });
    }
    
    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }
    
    render() {
        return (
            <div className="textInputContainer">
                <span className="textInputLabel">{this.props.title}</span>
                <select placeholder={this.props.title} value={this.props.value} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} disabled={this.props.disabled} className="dropDownInput">
                    {this.props.options.map(opt => <option key={opt.value} value={opt.value}>{opt.display}</option>)}
                </select>
            </div>
        );
    }
}