import Auth from "./Auth.js";

class Client {
    constructor() {
        this.baseUrl = "";
    }

    async login(loginRequest) {
        return await this.wrappedRequest(() =>
            this.postRequest("/api/login", loginRequest));
    }

    async getFileRoomStatus() {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated("/api/file-room-status"));
    }

    async getBoschCodes(pageNumber, searchTerm) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/bosch-codes?pageNumber=${pageNumber}` + (searchTerm !== null && searchTerm !== "" ? `&searchTerm=${searchTerm}` : "")));
    }

    async getPCodes(pageNumber, searchTerm) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/p-codes?pageNumber=${pageNumber}` + (searchTerm !== null && searchTerm !== "" ? `&searchTerm=${searchTerm}` : "")));
    }

    async getUsers() {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated("/api/users"));
    }

    async getSelf() {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated("/api/users/me"));
    }

    async getFileRequests(userId) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/file-requests/${userId}`));
    }

    async getUserCreditHistory(userId) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/credit/${userId}`));
    }

    async addCredit(addCreditRequest) {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated(`/api/credit`, addCreditRequest));
    }

    async postComment(postCommentRequest) {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated(`/api/comment`, postCommentRequest));
    }

    async markCommentsSeen(fileRequestId) {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated(`/api/file-request/${fileRequestId}/comments/seen`, null));
    }
    
    async setFileRoomStatus(status) {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated(`/api/file-room-status?status=${status}`, null));
    }

    async createUser(createUserRequest) {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated(`/api/users`, createUserRequest));
    }
    
    async updateUser(updateUserRequest) {
        return await this.wrappedRequest(() =>
            this.putRequestAuthenticated(`/api/users/${updateUserRequest.id}`, updateUserRequest));
    }

    async createFileRequest(createFileRequestRequest) {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated(`/api/file-request`, createFileRequestRequest));
    }
    
    async updateFileRequestStatus(fileRequestId, status) {
        return await this.wrappedRequest(() =>
            this.putRequestAuthenticated(`/api/file-request/${fileRequestId}/status/${status}`, null));
    }

    async rejectFileRequest(fileRequestId) {
        return await this.wrappedRequest(() =>
            this.putRequestAuthenticated(`/api/file-request/${fileRequestId}/reject`, null));
    }

    async awaitingGarageFileRequest(fileRequestId) {
        return await this.wrappedRequest(() =>
            this.putRequestAuthenticated(`/api/file-request/${fileRequestId}/awaiting`, null));
    }

    async getMakes() {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated("/api/makes"));
    }

    async getModels(make) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/models?make=${make}`));
    }

    async getGenerations(make, model) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/generations?make=${make}&model=${model}`));
    }

    async getEngines(make, model, generation) {
        return await this.wrappedRequest(() =>
            this.getRequestAuthenticated(`/api/engines?make=${make}&model=${model}&generation=${generation}`));
    }
    
    async logout() {
        return await this.wrappedRequest(() =>
            this.postRequestAuthenticated("/api/logout"));
    }
    
    async downloadFileRequestUpload(fileRequestId, uploadId) {
        const response = await this.getRequestAuthenticated(`/api/file-request/${fileRequestId}/uploads/${uploadId}`);

        if (response.status !== 200) {
            throw "Couldn't download the thingy"
        }

        const header = response.headers.get('Content-Disposition');
        const parts = header.split(';');
        const filename = parts[1].split('=')[1].replaceAll("\"", "")

        const blob = await response.blob()
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    async downloadFileRequestDownload(fileRequestId, downloadId) {
        const response = await this.getRequestAuthenticated(`/api/file-request/${fileRequestId}/downloads/${downloadId}`);

        if (response.status !== 200) {
            throw "Couldn't download the thingy"
        }

        const header = response.headers.get('Content-Disposition');
        const parts = header.split(';');
        const filename = parts[1].split('=')[1].replaceAll("\"", "")

        const blob = await response.blob()
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    async uploadFileRequestDownload(fileRequestId, description, fileName, file) {
        return await this.wrappedRequest(() => {
            const authToken = Auth.getAuthToken();

            if (authToken === null) {
                return;
            }

            const data = new FormData()
            data.append("upload", file)

            return fetch(this.baseUrl + `/api/file-request/${fileRequestId}/downloads?name=${btoa(fileName)}&description=${btoa(description)}`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + authToken,
                },
                body: data
            });
        });
    }

    async uploadFileRequestUpload(fileRequestId, description, fileName, file) {
        return await this.wrappedRequest(() => {
            const authToken = Auth.getAuthToken();

            if (authToken === null) {
                return;
            }

            const data = new FormData()
            data.append("upload", file)

            return fetch(this.baseUrl + `/api/file-request/${fileRequestId}/uploads?name=${btoa(fileName)}&description=${btoa(description)}`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + authToken,
                },
                body: data
            });
        });
    }

    postRequest(url, body) {
        const headers = {
            "Content-Type": "application/json"
        };
        
        return fetch(this.baseUrl + url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        });
    }

    postRequestAuthenticated(url, body) {
        const authToken = Auth.getAuthToken();

        if (authToken === null) {
            return;
        }

        const headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        };

        return fetch(this.baseUrl + url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        });
    }

    putRequestAuthenticated(url, body) {
        const authToken = Auth.getAuthToken();

        if (authToken === null) {
            return;
        }

        const headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        };

        return fetch(this.baseUrl + url, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body)
        });
    }

    deleteRequestAuthenticated(url, body) {
        const authToken = Auth.getAuthToken();

        if (authToken === null) {
            return;
        }

        const headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        };

        return fetch(this.baseUrl + url, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(body)
        });
    }

    getRequest(url) {
        const headers = {
            "Content-Type": "application/json"
        };
        
        return fetch(this.baseUrl + url, {
            method: "GET",
            headers: headers
        });
    }

    getRequestAuthenticated(url) {
        const authToken = Auth.getAuthToken();
        
        if (authToken === null) {
            return;
        }

        const headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        };

        return fetch(this.baseUrl + url, {
            method: "GET",
            headers: headers
        });
    }

    getRequestAuthenticatedIfPossible(url, keepalive = false) {
        const authToken = Auth.getAuthTokenIfPossible();

        const headers = {
            "Content-Type": "application/json"
        };

        const request = {
            method: "GET",
            headers: headers,
            keepalive: keepalive
        }
        
        if (authToken !== null) {
            request.headers.Authorization = "Bearer " + authToken
        }
        
        return fetch(this.baseUrl + url, request);
    }
    
    async wrappedRequest(request) {
        try {
            const response = await request();
            
            let error = null;
            let body = null;
            try {
                body = await response.json();
            } catch (e) {
                error = "Unknown error occured"
            }

            if (response.status === 401 && this.authIssueHandler != null) {
                this.authIssueHandler()
            }
            
            return {
                status: response.status,
                body: body,
                error: error 
            };
        } catch (e) {
            console.error("Something went wrong making request: ", e)
            return {
                status: null,
                body: null,
                error: "Unknown error occured"
            };
        }
    }
}

export default new Client();
