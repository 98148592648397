import { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, 
    Redirect
} from "react-router-dom";
import { routes } from "./routes"
import Auth from "./Auth"
import Client from "./Client"
import PreAuthLayout from "./layouts/PreAuthLayout"
import PostAuthLayout from "./layouts/PostAuthLayout"

export default class App extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route key={-1} path="/" exact={true}>
                        {!Auth.isLoggedIn() && <Redirect to="login" />}
                        {Auth.isLoggedIn() && Auth.isAdmin() && <Redirect to="admin" />}
                        {Auth.isLoggedIn() && ! Auth.isAdmin() && <Redirect to="app" />}
                    </Route>
                    {routes.map((route, index) => {
                        if (route.link) return null

                        return <Route key={index} path={route.path} exact={route.exact}>
                                {route.preauth && route.page && <PreAuthLayout>
                                    <route.page/>
                                </PreAuthLayout>}
                                {!route.preauth && route.page && <PostAuthLayout>
                                    <route.page/>
                                </PostAuthLayout>}
                            </Route>
                        }).filter(thingy => thingy !== null)}
                    <Redirect to="/not-found" />
                </Switch>
            </Router>
        );
    }
}
