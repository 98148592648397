import * as React from "react";

export default class SubmitButton extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    
    render() {
        return <div className="buttonStrip">
            <span className="errorText">{this.props.errorMessage}</span>
            <button className="submitButton" type="submit" disabled={this.props.disabled}>{this.props.title}</button>
        </div>
    }
}