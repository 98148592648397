import * as React from "react";

export default class HintTextInput extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            active: false
        };
        
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onFocus() {
        this.setState({
            active: true
        });
    }
    
    onBlur() {
        this.setState({
            active: false
        });
    }
    
    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }
    
    render() {
        if (this.props.type === "text" || this.props.type === "password") {
            return (
                <div className="textInputContainer">
                    <input placeholder={this.props.title} type={this.props.type} value={this.props.value} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} disabled={this.props.disabled} maxLength={this.props.maxLength} className="textInput" />
                </div>
            );
        } else if (this.props.type === "multiline-text") {
            return (
                <div className="textInputContainer">
                    <textarea placeholder={this.props.title} value={this.props.value} onFocus={this.onFocus} onBlur={this.onBlur} disabled={this.props.disabled} onChange={this.onChange} className="textInput" />
                </div>
            );
        }
    }
}