import * as React from "react";
import logo from "../images/medlicott-motors.webp";
import HintTextInput from "../components/HintTextInput";
import SubmitButton from "../components/SubmitButton";
import Auth from "../Auth"
import Client from "../Client"
import {Redirect} from "react-router-dom";

export default class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            email: "",
            password: "",
            loggingIn: false,
            loginFailureMessage: null,
            success: false,
            isAdmin: null
        };
    }

    setStateProperty = (property) => {
        return (value) => {
            this.setState({
                [property]: value
            });
        }
    }
    
    login = async (e) => {
        e.preventDefault()
        
        this.setState({
            loggingIn: true,
            loginFailureMessage: null
        });

        const result = await Client.login({
            email: this.state.email,
            password: this.state.password
        });

        if (result.status !== 200) {
            this.setState({
                loginFailureMessage: result.error || result.body.message,
                loggingIn: false
            });

            return;
        }

        Auth.setLoggedIn(result.body.authToken, result.body.validUntil, result.body.userId, result.body.isAdmin, result.body);
        
        this.setState({
            success: true,
            isAdmin: result.body.isAdmin
        })
    }
    
    render() {
        if (this.state.success && this.state.isAdmin) {
            return <Redirect to="admin" />
        }

        if (this.state.success && ! this.state.isAdmin) {
            return <Redirect to="app" />
        }
        
        return <div id="loginPageContainer">
            <div id="loginDialog">
                <div>
                    <img src={logo} className="loginLogo" />
                    <form onSubmit={this.login}>
                        <HintTextInput type="text" title="Email Address" disabled={this.state.loggingIn} onChange={this.setStateProperty("email")} />
                        <HintTextInput type="password" title="Password" disabled={this.state.loggingIn} onChange={this.setStateProperty("password")} />
                        <SubmitButton title="Login" disabled={this.state.loggingIn} />
                        {this.state.loginFailureMessage && <p className="errorText">{this.state.loginFailureMessage}</p>}
                    </form>
                    <p className="forgotCredentials">If you have forgotten your login details, please contact us.</p>
                </div>
            </div>
        </div>
    }
}
