import * as React from "react";
import Auth from "../Auth"
import Client from "../Client"
import { Redirect } from "react-router-dom";

export default class LogoutPage extends React.Component {
    componentDidMount() {
        Client.logout();
        Auth.logout();
    }
    
    render() {
        return <Redirect to="login" />
    }
}
