import * as React from "react";
import { Redirect } from "react-router-dom";
import Auth from "../Auth";
import Client from "../Client";
import logo from "../images/header-logo.png";
import ErrorUtils from "../ErrorUtils";
import DateUtils from "../DateUtils";
import StatusUtils from "../StatusUtils";
import ExpandyBoi from "../components/ExpandyBoi";
import TextInput from "../components/TextInput";
import DropDownInput from "../components/DropDownInput";
import SubmitButton from "../components/SubmitButton";
import clone from "just-clone";
import Tabs from "../components/Tabs";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from "react-toastify";

export default class AppPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            sendToLogout: ! Auth.isLoggedIn() || Auth.isAdmin(),
            loadingFileRoomStatus: false,
            loadFileRoomStatusError: null,
            fileRoomStatus: null,
            fileRequests: null,
            loadingFileRequests: true,
            loadFileRequestsError: null,
            dialog: null,
            loadingSelf: true,
            loadSelfError: null,
            self: null
        }

        this.loggedInUser = Auth.getUserDetails()

        this.readTypeOptions = [{
            display: "Full Read",
            value: "FullRead"
        }, {
            display: "VR",
            value: "VR"
        }]
        
        this.systemUsedForReadOptions = [{
            display: "Engine OBD",
            value: "EngineOBD"
        }, {
            display: "Engine Boot",
            value: "EngineBoot"
        }, {
            display: "Engine Bench",
            value: "EngineBench"
        }, {
            display: "Gearbox OBD",
            value: "GearboxOBD"
        }, {
            display: "Gearbox Bench Boot",
            value: "GearboxBenchBoot"
        }]
        
        this.mapTypes = [
            {id: "4365954b-91c7-49b1-bec6-3554ee8541c2", name: "ADBLUE/SCR OFF"},
            {id: "1c56d795-f297-40ec-b824-c31f54fc18b6", name: "COLD START NOISE"},
            {id: "419c4d36-027c-4ccb-9bcb-eeb1ad058320", name: "EGR OFF"},
            {id: "0a985eb4-0a7a-4e6b-90c4-aa1d9c4f863c", name: "EXHAUST FLAP REMOVAL"},
            {id: "3f81a7a4-9d45-4f02-b2d6-3e1cff2646d7", name: "HOT START"},
            {id: "a8d0568b-e4e0-4605-ad0b-251327721388", name: "O2/LAMBDA OFF"},
            {id: "c496bab1-64ca-4aed-b78b-27b33b10cae7", name: "START STOP DISABLE"},
            {id: "f4bf102c-352d-4dfe-afc3-1e2458b2a71f", name: "DPF/FAP OFF"},
            {id: "ad08ceda-0f43-4bc1-b665-f79e678d36d6", name: "GPF/OPF OFF"},
            {id: "1a349faf-2824-459c-8e35-1a0a4d2d4876", name: "SWIRL FLAPS OFF"},
            {id: "9636a042-141e-4ff9-9b6d-a90327693596", name: "DTC OFF"},
            {id: "c2a45a34-8fb7-45bd-b581-569dfddfc3dd", name: "POP & BANG (PETROL ONLY)"},
            {id: "06c6bc32-6c6f-41bf-aeba-10d7ee012e3c", name: "SPEED LIMIT OFF"},
            {id: "d4d25f63-4c5f-4ebe-9ea6-fbb6c2d4a7de", name: "TQ MONITORING OFF"},
            {id: "fb57e119-ba5c-4444-a3f2-54f3fecca6f0", name: "STAGE 1"},
            {id: "5b688474-df31-4c99-b846-d6a002cc8868", name: "STAGE 2"},
            {id: "b11f7a3b-c971-4ad4-b7ea-c729a9acd856", name: "NO TUNING"},
            {id: "73ca9d46-eab2-4333-97af-8e4c2a806c6c", name: "ECO-MAP"}
        ]
    }

    hideDialog = () => this.setState({
        dialog: null
    })

    showNewFileRequestDialog = (make = "", model = "", stockHPTQ = "", fuelType = "", ecu = "", engineCC = "") => {
        console.info("make is", make)
        this.setState({
            dialog: {
                type: "newFileRequest",
                title: "New File Request",
                make: make,
                model: model,
                yearOfManufacture: null,
                engineCC: engineCC,
                transmissionType: "",
                stockHPTQ: stockHPTQ,
                registration: "",
                mileage: null,
                fuelType: fuelType,
                readType: "FullRead",
                systemUsedForRead: "EngineOBD",
                ecu: ecu,
                modifications: "",
                notes: "",
                requestedMapTypes: [],
                confirm: false
            }
        })
    }
    
    

    showEngineStatsDialog = () => {
        this.setState({
            dialog: {
                type: "engineStats",
                title: "Check Engine Stats"
            },
            loadingEngineStats: true,
            selectedEngineMake: "none",
            selectedEngineModel: "none",
            selectedEngineGeneration: "none",
            selectedEngineEngine: "none",
            engineMakes: null,
            engineModels: null,
            engineGenerations: null,
            engineEngines: null
        })
        
        this.loadEngineMakes()
    }

    engineStatsMakeChanged = (value) => {
        if (value === "none") {
            this.setState({
                selectedEngineMake: value
            })
            
            return
        }
        
        this.setState({
            selectedEngineMake: value,
            loadingEngineStats: true
        })
        
        this.loadEngineModels(value)
    }

    engineStatsModelChanged = (value) => {
        if (value === "none") {
            this.setState({
                selectedEngineModel: value
            })

            return
        }

        this.setState({
            selectedEngineModel: value,
            loadingEngineStats: true
        })

        this.loadEngineGenerations(this.state.selectedEngineMake, value)
    }

    engineStatsGenerationChanged = (value) => {
        if (value === "none") {
            this.setState({
                selectedEngineGeneration: value
            })

            return
        }

        this.setState({
            selectedEngineGeneration: value,
            loadingEngineStats: true
        })

        this.loadEngineEngines(this.state.selectedEngineMake, this.state.selectedEngineModel, value)
    }

    engineStatsEngineChanged = (value) => {
        this.setState({
            selectedEngineEngine: value
        })
    }

    showCodesDialog = () => {
        this.setState({
            dialog: {
                type: "codes",
                title: "Bosch Codes / P-Codes"
            },
            loadingBoschCodes: true,
            loadingPCodes: true,
            boschCodesPageNumber: 1,
            pCodesPageNumber: 1,
            boschCodesSearchTerm: "",
            pCodesSearchTerm: "",
            boschCodesPage: null,
            pCodesPage: null,
            previousBoschCodesSearchTerm: "",
            previousPCodesSearchTerm: ""
        }, () => this.loadBoschCodes() && this.loadPCodes())
    }

    loadBoschCodes = async () => {
        this.setState({
            loadingBoschCodes: true,
            loadBoschCodesError: null,
            boschCodesPage: null,
            previousBoschCodesSearchTerm: this.state.boschCodesSearchTerm
        })

        let pageNumberToUse = this.state.boschCodesPageNumber
        if (this.state.boschCodesSearchTerm !== this.state.previousBoschCodesSearchTerm) {
            pageNumberToUse = 1
        }
        
        const result = await Client.getBoschCodes(pageNumberToUse, this.state.boschCodesSearchTerm)

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingBoschCodes: false,
                loadBoschCodesError: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load Bosch Codes")
            })

            return;
        }

        this.setState({
            loadingBoschCodes: false,
            boschCodesPage: result.body,
            boschCodesPageNumber: result.body.pageNumber
        })
    }

    loadPCodes = async () => {
        this.setState({
            loadingPCodes: true,
            loadPCodesError: null,
            pCodesPage: null,
            previouspCodesSearchTerm: this.state.pCodesSearchTerm
        })

        let pageNumberToUse = this.state.pCodesPageNumber
        if (this.state.pCodesSearchTerm !== this.state.previousPCodesSearchTerm) {
            pageNumberToUse = 1
        }

        const result = await Client.getPCodes(pageNumberToUse, this.state.pCodesSearchTerm)

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingPCodes: false,
                loadPCodesError: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load P Codes")
            })

            return;
        }

        this.setState({
            loadingPCodes: false,
            pCodesPage: result.body,
            pCodesPageNumber: result.body.pageNumber
        })
    }

    loadEngineMakes = async () => {
        this.setState({
            loadingEngineStats: true,
            engineMakes: null
        })
        
        const result = await Client.getMakes()

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingEngineStats: false,
                loadEngineStatsErrors: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load Engine Makes")
            })

            return;
        }

        this.setState({
            loadingEngineStats: false,
            engineMakes: result.body
        })
    }

    loadEngineModels = async (make) => {
        this.setState({
            loadingEngineStats: true,
            engineModels: null
        })

        const result = await Client.getModels(make)

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingEngineStats: false,
                loadEngineStatsErrors: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load Engine Models")
            })

            return;
        }

        this.setState({
            loadingEngineStats: false,
            engineModels: result.body
        })
    }

    loadEngineGenerations = async (make, model) => {
        this.setState({
            loadingEngineStats: true,
            engineGenerations: null
        })

        const result = await Client.getGenerations(make, model)

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingEngineStats: false,
                loadEngineStatsErrors: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load Engine Generations")
            })

            return;
        }

        this.setState({
            loadingEngineStats: false,
            engineGenerations: result.body
        })
    }

    loadEngineEngines = async (make, model, generation) => {
        this.setState({
            loadingEngineStats: true,
            engineEngines: null
        })

        const result = await Client.getEngines(make, model, generation)

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingEngineStats: false,
                loadEngineStatsErrors: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load Engine Engines")
            })

            return;
        }

        this.setState({
            loadingEngineStats: false,
            engineEngines: result.body
        })
    }

    componentDidMount() {
        this.loadFileRoomStatus()
        setInterval(this.loadFileRoomStatus, 60000)
        
        this.loadFileRequests()
        this.loadSelf()
    }

    loadFileRoomStatus = async () => {
        if (this.state.loadingFileRoomStatus || this.state.settingFileRoomStatus) return;

        this.setState({
            loadingFileRoomStatus: true,
            loadFileRoomStatusError: null
        });

        const result = await Client.getFileRoomStatus();

        if (result.status !== 200) {
            if (result.status === 401) this.logout()
            
            this.setState({
                loadingFileRoomStatus: false,
                loadFileRoomStatusError: true
            });

            return;
        }

        this.setState({
            loadingFileRoomStatus: false,
            fileRoomStatus: result.body.status
        });
    }
    
    loadFileRequests = async () => {
        this.setState({
            loadingFileRequests: true,
            loadFileRequestsError: null
        });

        const result = await Client.getFileRequests(this.loggedInUser.userId);

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingFileRequests: false,
                loadFileRequestsError: ErrorUtils.messageFromBodyOrDefault(result, "Something went wrong fetching File Requests")
            });

            return;
        }

        this.setState({
            loadingFileRequests: false,
            fileRequests: result.body
        });
    }

    setStateFileRequest = (fileRequestId, property) => {
        return (value) => {
            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextStateFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequestId)[0]

                nextStateFileRequest[property] = value

                return nextState
            });
        }
    }

    loadSelf = async (e) => {
        if (e) e.preventDefault()
        
        this.setState({
            loadingSelf: true,
            loadSelfError: null
        })

        const result = await Client.getSelf()

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState({
                loadingSelf: false,
                loadSelfError: ErrorUtils.messageFromBodyOrDefault(result, "Unable to load self")
            })

            return;
        }

        this.setState({
            loadingSelf: false,
            self: result.body
        })
    }
    
    downloadUpload = (fileRequestId, uploadId) => {
        return async () => {
            try {
                await Client.downloadFileRequestUpload(fileRequestId, uploadId)
            } catch (error) {
                this.errorToast("Could not download file")
            }
        }
    }

    downloadDownload = (fileRequestId, downloadId) => {
        return async () => {
            try {
                await Client.downloadFileRequestDownload(fileRequestId, downloadId)
            } catch (error) {
                this.errorToast("Could not download file")
            }
        }
    }

    triggerUploadDialog = (fileRequestId) => {
        return (e) => {
            e.preventDefault()
            document.getElementById("upload-file-" + fileRequestId).click()
        }
    }

    triggerUploadDialogNew = (e) => {
        e.preventDefault()
        document.getElementById("upload-file-dialog").click()
    }

    onFileSelected = (fileRequest) => {
        return async (event) => {
            event.preventDefault()

            if (event.target.files && event.target.files[0]) {
                this.setStateFileRequest(fileRequest.id, "uploadFile")(event.target.files[0])
            }
        }
    }

    onFileSelectedNew = async (event) => {
        event.preventDefault()

        if (event.target.files && event.target.files[0]) {
            this.setStateDialogProperty("uploadFile")(event.target.files[0])
        }
    }

    submitFileUpload = (fileRequest) => {
        return async (e) => {
            e.preventDefault()

            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.uploadingFile = true
                nextFileRequest.uploadError = null

                return nextState
            })

            const result = await Client.uploadFileRequestUpload(fileRequest.id, fileRequest.uploadFileDescription || "", fileRequest.uploadFile.name, fileRequest.uploadFile);

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)
                    
                    const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.uploadingFile = false
                    nextFileRequest.uploadError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to upload file")
                    nextFileRequest.uploadFile = null
                    nextFileRequest.uploadFileDescription = ""

                    return nextState
                })

                return
            }

            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.uploadingFile = false
                nextFileRequest.uploadFile = null
                nextFileRequest.uploadFileDescription = ""

                nextFileRequest.uploads.unshift(result.body)

                return nextState
            })

            this.infoToast("Succesfully uploaded file")
        }
    }

    postFileRequestComment = (fileRequest) => {
        return async (e) => {
            e.preventDefault()

            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.postingComment = true
                nextFileRequest.postCommentError = null

                return nextState
            })

            const result = await Client.postComment({
                fileRequestId: fileRequest.id,
                comment: fileRequest.newComment
            })

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)
                    
                    const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.postingComment = false
                    nextFileRequest.postCommentError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to post comment")

                    return nextState
                })

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.comments.push(result.body)

                nextFileRequest.postingComment = false
                nextFileRequest.newComment = ""

                return nextState
            })
        }
    }

    infoToast = (message) => {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    errorToast = (message) => {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    
    render() {
        if (this.state.sendToLogout) return <Redirect to="logout" />

        return <>
            {this.renderHeader()}
            <div id="mainContentContainer">
                {this.renderFileRequests()}
            </div>
            {this.state.dialog && <>
                <div id="dialogBackdrop" onClick={this.hideDialog} />
                <div id="dialog">
                    <div className="buttonStrip dialogButtonStrip">
                        <span id="dialogHeader">{this.state.dialog.title}</span>
                        <button onClick={this.hideDialog}>Close</button>
                    </div>
                    {this.state.dialog.type === "newFileRequest" && this.renderNewFileRequestDialog()}
                    {this.state.dialog.type === "codes" && this.renderCodesDialog()}
                    {this.state.dialog.type === "engineStats" && this.renderEngineStats()}
                </div>
            </>}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    }

    setStateProperty = (property) => {
        return (e) => {
            this.setState({
                [property]: e.target.value
            });
        }
    }
    
    boschCodesPageChanged = (e) => {
        this.setState({
            boschCodesPageNumber: e.target.value
        }, this.loadBoschCodes);
    }

    pCodesPageChanged = (e) => {
        this.setState({
            pCodesPageNumber: e.target.value
        }, this.loadPCodes);
    }
    
    setStateDialogProperty = (property) => {
        return (value) => {
            this.setState(prevState => {
                const nextState = clone(prevState)

                nextState.dialog[property] = value

                return nextState
            });
        }
    }

    setStateDialogPropertyCheck = (property) => {
        return (e) => {
            this.setState(prevState => {
                const nextState = clone(prevState)

                nextState.dialog[property] = e.target.checked

                return nextState
            });
        }
    }
    
    getPageNumbersArray = (totalPages) => {
        let pageNumbersArray = []
        for(let i = 1; i <= totalPages; i++) {
            pageNumbersArray.push(i)
        }
        
        return pageNumbersArray
    }

    renderEngineStats = () => {
        if (this.state.loadingEngineStats) {
            return <p>Loading...</p>
        }
        
        if (this.state.loadEngineStatsErrors) {
            return <p className="errorText">{this.state.loadEngineStatsErrors}</p>
        }
        
        const engineMakeOptions = [{
            display: "Select a make",
            value: "none"
        }]

        this.state.engineMakes.forEach(make => {
            engineMakeOptions.push({
                display: make,
                value: make
            })
        })
        
        let engineModelOptions = null
        if (this.state.engineModels) {
            engineModelOptions = [{
                display: "Select a make",
                value: "none"
            }]

            this.state.engineModels.forEach(model => {
                engineModelOptions.push({
                    display: model,
                    value: model
                })
            })
        }

        let engineGenerationOptions = null
        if (this.state.engineGenerations) {
            engineGenerationOptions = [{
                display: "Select a generation",
                value: "none"
            }]

            this.state.engineGenerations.forEach(generation => {
                engineGenerationOptions.push({
                    display: generation,
                    value: generation
                })
            })
        }

        let engineEngineOptions = null
        if (this.state.engineEngines) {
            engineEngineOptions = [{
                display: "Select an engine",
                value: "none"
            }]

            this.state.engineEngines.forEach(engine => {
                engineEngineOptions.push({
                    display: engine.engine,
                    value: engine.id
                })
            })
        }
        
        let selectedEngine = null
        if (this.state.selectedEngineEngine !== "none") {
            selectedEngine = this.state.engineEngines.filter(en => en.id === this.state.selectedEngineEngine)[0]
        }
        
        return <>
            <DropDownInput title="Make" disabled={false} value={this.state.selectedEngineMake} onChange={this.engineStatsMakeChanged} options={engineMakeOptions} />
            {this.state.engineModels && <DropDownInput title="Model" disabled={false} value={this.state.selectedEngineModel} onChange={this.engineStatsModelChanged} options={engineModelOptions} />}
            {this.state.engineGenerations && <DropDownInput title="Generations" disabled={false} value={this.state.selectedEngineGeneration} onChange={this.engineStatsGenerationChanged} options={engineGenerationOptions} />}
            {this.state.engineEngines && <DropDownInput title="Engines" disabled={false} value={this.state.selectedEngineEngine} onChange={this.engineStatsEngineChanged} options={engineEngineOptions} />}
            {this.state.selectedEngineEngine !== "none" && <div>
                <p className="mapStat">Standard - HP: {selectedEngine.bhpStandard}, Torque: {selectedEngine.torqueStandard}</p>
                {selectedEngine.torqueStage1 && <p className="mapStat">Stage 1 - HP: {selectedEngine.bhpStage1}, Torque: {selectedEngine.torqueStage1}</p>}
                {selectedEngine.bhpStage2 && <p className="mapStat">Stage 2 - HP: {selectedEngine.bhpStage2}, Torque: {selectedEngine.torqueStage2}</p>}
                {selectedEngine.bhpStage3 && <p className="mapStat">Stage 3 - HP: {selectedEngine.bhpStage3}, Torque: {selectedEngine.torqueStage3}</p>}
                <button className="downloadButton" onClick={() => this.showNewFileRequestDialog(selectedEngine.make, selectedEngine.model, `${selectedEngine.bhpStandard} / ${selectedEngine.torqueStandard}`, selectedEngine.fuelType, selectedEngine.ecu, selectedEngine.engineCC)}>Request new File for this Engine</button>
            </div>}
        </>
    }
    
    renderCodesDialog = () => {
        return <Tabs tabs={[{
            title: "Bosch Codes",
            content: <div>
                {this.state.loadingBoschCodes && <p>Loading...</p>}
                {this.state.loadBoschCodesError && <p className="errorText">{this.state.loadBoschCodesError}</p>}
                {this.state.boschCodesPage && <>
                    <div className="codesSearch">
                        <form onSubmit={this.loadBoschCodes}>
                            <span>
                                <label>Search: </label>
                                <input type="text" value={this.state.boschCodesSearchTerm} onChange={this.setStateProperty("boschCodesSearchTerm")} />
                                <button>Search</button>
                            </span>
                            <span>
                                <label>Page: </label>
                                <select value={this.state.boschCodesPageNumber} onChange={this.boschCodesPageChanged}>
                                    {this.getPageNumbersArray(this.state.boschCodesPage.totalPages).map(pageNumber => <option key={pageNumber} value={pageNumber}>{pageNumber}</option>)}
                                </select>
                            </span>
                        </form>
                    </div>
                    <table className="codesTable">
                        <thead>
                            <tr>
                                <td>Manufacturer Number</td>
                                <td>ECU</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.boschCodesPage.results.map(res => 
                                <tr key={res.manufacturerNumber}>
                                    <td>{res.manufacturerNumber}</td>
                                    <td>{res.ecu}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>}
            </div>
        }, {
            title: "P-Codes",
            content: <div>
                {this.state.loadingPCodes && <p>Loading...</p>}
                {this.state.loadPCodesError && <p className="errorText">{this.state.loadPCodesError}</p>}
                {this.state.pCodesPage && <>
                    <div className="codesSearch">
                        <form onSubmit={this.loadPCodes}>
                            <span>
                                <label>Search: </label>
                                <input type="text" value={this.state.pCodesSearchTerm} onChange={this.setStateProperty("pCodesSearchTerm")} />
                                <button>Search</button>
                            </span>
                            <span>
                                <label>Page: </label>
                                <select value={this.state.pCodesPageNumber} onChange={this.pCodesPageChanged}>
                                    {this.getPageNumbersArray(this.state.pCodesPage.totalPages).map(pageNumber => <option key={pageNumber} value={pageNumber}>{pageNumber}</option>)}
                                </select>
                            </span>
                        </form>
                    </div>
                    <table className="codesTable">
                        <thead>
                        <tr>
                            <td>Code</td>
                            <td>DF Code</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.pCodesPage.results.map(res =>
                            <tr key={res.code}>
                                <td>{res.code}</td>
                                <td>{res.dfCode === "null" ? "N/A" : res.dfCode}</td>
                                <td>{res.description}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </>}
            </div>
        }]} />
    }
    
    renderNewFileRequestDialog = () => {
        if (this.state.self.credits === 0) {
            return <p className="errorText">You do not have any credits so cannot request a file.  Contact us to get credits added to your account.</p>
        }
        
        return <div>
            <form onSubmit={this.createFileRequest}>
                <TextInput type="text" title="Vehicle Make" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.make} onChange={this.setStateDialogProperty("make")} />
                <TextInput type="text" title="Model" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.model} onChange={this.setStateDialogProperty("model")} />
                <TextInput type="number" title="Year of Manufacture" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.yearOfManufacture} onChange={this.setStateDialogProperty("yearOfManufacture")} />
                <TextInput type="number" title="Engine CC" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.engineCC} onChange={this.setStateDialogProperty("engineCC")} />
                <TextInput type="text" title="Transmission Type" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.transmissionType} onChange={this.setStateDialogProperty("transmissionType")} />
                <TextInput type="text" title="Stock HP/TQ" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.stockHPTQ} onChange={this.setStateDialogProperty("stockHPTQ")} />
                <TextInput type="text" title="Registration" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.registration} onChange={this.setStateDialogProperty("registration")} />
                <TextInput type="number" title="Mileage" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.mileage} onChange={this.setStateDialogProperty("mileage")} />
                <TextInput type="text" title="Fuel Type" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.fuelType} onChange={this.setStateDialogProperty("fuelType")} />
                <DropDownInput title="Read Type" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.readType} onChange={this.setStateDialogProperty("readType")} options={this.readTypeOptions} />
                <DropDownInput title="System used for read" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.systemUsedForRead} onChange={this.setStateDialogProperty("systemUsedForRead")} options={this.systemUsedForReadOptions} />
                <TextInput type="text" title="ECU" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.ecu} onChange={this.setStateDialogProperty("ecu")} />
                <TextInput type="text" title="Modifications (if any)" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.modifications} onChange={this.setStateDialogProperty("modifications")} />
                <TextInput type="text" title="Notes (Optional)" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.notes} onChange={this.setStateDialogProperty("notes")} />
                <p>Maps to Apply</p>
                {this.mapTypes.map(mt => <div key={mt.id}><input onChange={this.dialogMapTypeCheckChange(mt.id)} type="checkbox" id={mt.id + "-checkbox"} /><label htmlFor={mt.id + "-checkbox"}>{mt.name}</label></div>)}
                <p>File Reading</p>
                <button className="downloadButton" onClick={this.triggerUploadDialogNew}>Select File</button>
                {this.state.dialog.uploadFile && <em className="uploadFileName">{this.state.dialog.uploadFile.name}</em>}
                <TextInput type="text" title="File Description (Optional)" disabled={this.state.dialog.creatingFileRequest} value={this.state.dialog.uploadFileDescription} onChange={this.setStateDialogProperty("uploadFileDescription")} />
                <input id={"upload-file-dialog"} type="file" style={{display: "none"}} onChange={this.onFileSelectedNew} />
                <strong>Important Message</strong>
                <p>When uploading files from an ID or Virtual read, we have no way of knowing if vehicle has been previously modified. For us to provide you with a file, we match the vehicle's software numbers from the ID file to an original file from our database. We then apply the changes to this file.

                    If your tool provides you a Virtual read, this means it is an original from the tool providers online database. If you write the tuned file supplied from us, then all previous changes (if any) will be lost and there is no way of reverting back to its original state.</p>
                <p style={{textDecoration: "underline"}}>By Submitting this file, you Agree to our Terms & Conditions.</p>
                <p style={{fontWeight: "bold"}}>NO software should be shared online, in any forums or on any form of social platform.

                    Any user found doing so, their account will be instantly deactivated, and all credits will be lost without refund.

                    If you have any problems with any files, please use the WhatsApp Support group provided and one of our engineers will help you ASAP.</p>
                <p style={{fontWeight: "bold"}}>All files come with a 21 day warranty, any changes needed which are related to the original request will be Free during this time period.
                    Please make sure you read all text attached to tuned file in email.
                </p>
                <p>If a file comes to us already tuned, Please do your best to make us aware. In the event a file is tuned, we will change the tune to suit your requests. We will make you aware the file was tuned. if you choose to download the file, It is up to you to inform the end customer. Refunds will not be given because they can not feel a difference.
                    Gains will not be as noticeable due to the file already being tuned.
                </p>
                <p>Any and all damage to vehicles caused by reading and writing software to vehicles is the users responsibility and done at your own risk.</p>
                <p>We do not give credits because your tool can not write the file or the customer has changed their mind. In the event your tool does not write the file we will supply a new file for any chosen tool for the same car free of charge inside 21 days.</p>
                <p>It is your responsibility to make the customer aware of the risks of remapping a vehicle and to make sure the vehicle is fit for modification.</p>
                <p>All and any files are intended for OFF-ROAD Purposes only and can void manufactures warranty.</p>
                <input onChange={this.setStateDialogPropertyCheck("confirm")} type="checkbox" id="confirm-checkbox" /><label htmlFor="confirm-checkbox">I confirm</label>
                <SubmitButton errorMessage={this.state.dialog.createFileRequestError} title="Create File Request" disabled={this.state.dialog.creatingFileRequest || this.createFileRequestDisabled()} />
            </form>
        </div>
    }

    dialogMapTypeCheckChange = (mapTypeId) => {
        return (e) => {
            this.setState(prevState => {
                const nextState = clone(prevState)

                if (e.target.checked) {
                    nextState.dialog.requestedMapTypes.push(mapTypeId)
                } else {
                    nextState.dialog.requestedMapTypes = nextState.dialog.requestedMapTypes.filter(rmt => rmt === mapTypeId)
                }
                
                return nextState
            })
        }
    }

    createFileRequestDisabled = () => {
        return this.isNullOrBlank(this.state.dialog.make) ||
            this.isNullOrBlank(this.state.dialog.model) ||
            this.isNullOrBlank(this.state.dialog.yearOfManufacture) ||
            this.isNullOrBlank(this.state.dialog.engineCC) ||
            this.isNullOrBlank(this.state.dialog.transmissionType) ||
            this.isNullOrBlank(this.state.dialog.stockHPTQ) ||
            this.isNullOrBlank(this.state.dialog.registration) ||
            this.isNullOrBlank(this.state.dialog.mileage) ||
            this.isNullOrBlank(this.state.dialog.fuelType) ||
            this.isNullOrBlank(this.state.dialog.readType) ||
            this.isNullOrBlank(this.state.dialog.systemUsedForRead) ||
            this.isNullOrBlank(this.state.dialog.ecu) || 
            this.state.dialog.requestedMapTypes.length === 0 ||
            ! this.state.dialog.uploadFile || 
            ! this.state.dialog.confirm
    }
    
    isNullOrBlank = (thang) => thang === null || ("" + thang).trim() === ""

    logout = () => this.setState({
        sendToLogout: true
    })
    
    createFileRequest = async (e) => {
        e.preventDefault()

        this.setState(prevState => {
            const nextState = clone(prevState)
            
            nextState.dialog.creatingFileRequest = true
            nextState.dialog.createFileRequestError = null

            return nextState
        })

        const result = await Client.createFileRequest({
            make: this.state.dialog.make,
            model: this.state.dialog.model,
            yearOfManufacture: this.state.dialog.yearOfManufacture,
            engineCC: this.state.dialog.engineCC,
            transmissionType: this.state.dialog.transmissionType,
            stockHPTQ: this.state.dialog.stockHPTQ,
            registration: this.state.dialog.registration,
            mileage: this.state.dialog.mileage,
            fuelType: this.state.dialog.fuelType,
            readType: this.state.dialog.readType,
            systemUsedForRead: this.state.dialog.systemUsedForRead,
            ecu: this.state.dialog.ecu,
            modifications: this.state.dialog.modifications,
            notes: this.state.dialog.notes,
            requestedMapTypes: this.state.dialog.requestedMapTypes
        })

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState(prevState => {
                const nextState = clone(prevState)

                nextState.dialog.creatingFileRequest = false
                nextState.dialog.createFileRequestError = ErrorUtils.messageFromBodyOrDefault(result, "Unable to create File Request")

                return nextState
            })

            return;
        }

        this.setState(prevState => {
            const nextState = clone(prevState)
            
            nextState.fileRequests.unshift(result.body)
            nextState.dialog.justCreatedId = result.body.id
            
            nextState.self.credits -= 1

            return nextState
        }, this.submitNewFileUpload)
    }
    
    submitNewFileUpload = async () => {
        const result = await Client.uploadFileRequestUpload(this.state.dialog.justCreatedId, this.state.dialog.uploadFileDescription || "", this.state.dialog.uploadFile.name, this.state.dialog.uploadFile);

        if (result.status !== 200) {
            if (result.status === 401) this.logout()

            this.setState(prevState => {
                const nextState = clone(prevState)
                
                nextState.dialog = null
                
                this.errorToast("Created the File Request but failed to upload the reading - please try again")

                return nextState
            })

            return
        }

        this.setState(prevState => {
            const nextState = clone(prevState)

            const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === this.state.dialog.justCreatedId)[0]

            nextFileRequest.uploads.unshift(result.body)
            nextFileRequest.status = "Sent"
            nextState.dialog = null

            return nextState
        })
        
        this.infoToast("Succesfully requested a new File")
    }

    markFileRequestCommentSeen = (fileRequest) => {
        return async () => {
            if (fileRequest.markingCommentsSeen) return

            if (fileRequest.comments.filter(comment => comment.seenByGarageAt === null).length === 0) return

            this.setState(prevState => {
                const nextState = clone(prevState)
                
                const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                nextFileRequest.markingCommentsSeen = true

                return nextState
            })

            const result = await Client.markCommentsSeen(fileRequest.id)

            if (result.status !== 200) {
                if (result.status === 401) this.logout()

                this.setState(prevState => {
                    const nextState = clone(prevState)

                    const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]

                    nextFileRequest.markingCommentsSeen = false

                    return nextState
                })

                this.errorToast("Unable to mark comments as seen")

                return;
            }

            this.setState(prevState => {
                const nextState = clone(prevState)

                const nextFileRequest = nextState.fileRequests.filter(fr => fr.id === fileRequest.id)[0]
                
                nextFileRequest.comments.forEach(comment => {
                    if (comment.seenByGarageAt === null) {
                        comment.seenByGarageAt = new Date()
                    }
                })

                nextFileRequest.markingCommentsSeen = false

                return nextState
            })
        }
    }
    
    renderHeader = () => {
        return <div id="header">
            <img id="headerLogo" src={logo} />
            <span>
                <button onClick={this.showCodesDialog} className="codesButton">Bosch Codes / P-Codes</button>
                {this.renderCredits()}
                {this.renderFileRoomStatus()}
                <button onClick={this.logout} className="logoutButton" onClick={this.logout}>Logout</button>
            </span>
        </div>
    }

    renderFileRoomStatus = () => {
        if (this.state.loadingFileRoomStatus) {
            return <button className="fileRoomLoadingButton">File Room Status: Loading</button>
        }

        if (this.state.loadFileRoomStatusError) {
            return <button onClick={this.loadFileRoomStatus} className="fileRoomClosedButton">File Room Status: Error - click to retry</button>
        }

        if (this.state.fileRoomStatus === "Open") {
            return <button className="fileRoomOpenButton noHovering">File Room Status: Open</button>
        }

        return <button className="fileRoomClosedButton noHovering">File Room Status: Closed</button>
    }

    renderCredits = () => {
        if (this.state.loadingSelf) {
            return <button className="fileRoomLoadingButton">Credits: Loading</button>
        }

        if (this.state.loadSelfError) {
            return <button onClick={this.loadSelf} className="fileRoomClosedButton">Credits: Error - click to retry</button>
        }

        if (this.state.self.credits > 0) {
            return <button className="fileRoomOpenButton noHovering">Credits: {this.state.self.credits}</button>
        }

        return <button className="fileRoomClosedButton noHovering">Credits: 0</button>
    }
    
    renderFileRequests = () => {
        if (this.state.loadingFileRequests) {
            return <p>Loading File Requests....</p>
        }
        
        if (this.state.loadFileRequestsError) {
            return <p className="errorText">{this.state.loadFileRequestsError} <button onClick={this.loadFileRequests}>Try Again</button></p>
        }
        
        return <>
            <div className="buttonStrip">
                <span id="welcome">Welcome {this.loggedInUser.firstName} {this.loggedInUser.lastName}</span>
                <span>
                    <button className="addUserButton" onClick={this.showEngineStatsDialog}>Check Engine Stats</button>
                    <button className="addUserButton" onClick={() => this.showNewFileRequestDialog()}>New File Request</button>
                    <button className="addUserButton" onClick={this.loadFileRequests}>Refresh</button>
                </span>
            </div>
            {this.state.fileRequests.length === 0 && <p>You haven't requested any files yet.</p>}
            {this.state.fileRequests.map(fileRequest => {
                const labels = []

                if (fileRequest.status === "ReadyToDownload") {
                    labels.push(<span className="label greenLabel">Ready to Download</span>)
                } else {
                    labels.push(<span className="label orangeLabel">{StatusUtils.statusToFriendly(fileRequest.status)}</span>)
                }

                const unseenComments = fileRequest.comments.filter(comment => comment.seenByGarageAt === null).length

                if (unseenComments > 0) {
                    labels.push(<span className="label orangeLabel">{unseenComments} Unseen Comment(s)</span>)
                }
                
                return <ExpandyBoi nested={true} key={fileRequest.id} header={<span>{fileRequest.registration} - {fileRequest.yearOfManufacture} {fileRequest.make} {fileRequest.model} {labels}</span>}>
                    <div className="fileRequestInfo">
                        <div>
                            <p><strong>Make:</strong> {fileRequest.make}</p>
                            <p><strong>Model:</strong> {fileRequest.model}</p>
                            <p><strong>Year of Manufacture:</strong> {fileRequest.yearOfManufacture}</p>
                            <p><strong>Engine CC:</strong> {fileRequest.engineCC}</p>
                            <p><strong>Fuel Type:</strong> {fileRequest.fuelType}</p>
                        </div>
                        <div>
                            <p><strong>Transmission Type:</strong> {fileRequest.transmissionType}</p>
                            <p><strong>Stock HP/TQ:</strong> {fileRequest.stockHPTQ}</p>
                            <p><strong>Registration:</strong> {fileRequest.registration}</p>
                            <p><strong>Mileage:</strong> {fileRequest.mileage}</p>
                            <p><strong>Read Type:</strong> {fileRequest.readType}</p>
                        </div>
                        <div>
                            <p><strong>System Used for Read:</strong> {fileRequest.systemUsedForRead}</p>
                            <p><strong>ECU:</strong> {fileRequest.ecu}</p>
                            <p><strong>Modifications:</strong> {fileRequest.modifications}</p>
                            <p><strong>Notes:</strong> {fileRequest.notes}</p>
                        </div>
                    </div>
                    <div>
                        <p><strong>Maps to Apply</strong></p>
                        <ul>
                            {fileRequest.requestMaps.map(rm => <li key={rm.id}>{this.mapTypes.filter(mt => mt.id === rm.mapTypeId)[0].name}</li>)}
                        </ul>
                    </div>
                    <Tabs tabs={[{
                        title: "Files uploaded by you",
                        content: <div>
                            {fileRequest.uploads.length === 0 && <p>No uploads</p>}
                            {fileRequest.uploads.map(upload => {
                                return <div key={upload.id} className="comment">
                                    <strong>{upload.name} @ {DateUtils.dateToFriendly(upload.uploadedAt)}</strong>
                                    <p>{upload.description}</p>
                                    <button className="downloadButton" onClick={this.downloadUpload(fileRequest.id, upload.id)}>Download</button>
                                </div>
                            })}
                            <div className="comment">
                                <form onSubmit={this.submitFileUpload(fileRequest)}>
                                    <strong>New Upload</strong>
                                    <p>Upload a file for the File Room to work with</p>
                                    <button className="downloadButton" onClick={this.triggerUploadDialog(fileRequest.id)}>Select File</button>
                                    {fileRequest.uploadFile && <em className="uploadFileName">{fileRequest.uploadFile.name}</em>}
                                    <TextInput type="text" title="Description (Optional)" disabled={fileRequest.uploadingFile} value={fileRequest.uploadFileDescription} onChange={this.setStateFileRequest(fileRequest.id, "uploadFileDescription")} />
                                    <input id={"upload-file-" + fileRequest.id} type="file" style={{display: "none"}} onChange={this.onFileSelected(fileRequest)} />
                                    <SubmitButton errorMessage={fileRequest.uploadError} title="Upload File" disabled={fileRequest.uploadingFile || !fileRequest.uploadFile} />
                                </form>
                            </div>
                        </div>
                    }, {
                        title: "Downloads",
                        content: <div>
                            {fileRequest.downloads.length === 0 && <p>No downloads</p>}
                            {fileRequest.downloads.map(download => {
                                return <div key={download.id} className="comment">
                                    <strong>{download.name} @ {DateUtils.dateToFriendly(download.uploadedAt)}</strong>
                                    <p>{download.description}</p>
                                    <button className="downloadButton" onClick={this.downloadDownload(fileRequest.id, download.id)}>Download</button>
                                </div>
                            })}
                        </div>
                    }, {
                        title: "Comments" + (unseenComments > 0 ? " (" + unseenComments + " Unseen)" : ""),
                        onSelected: this.markFileRequestCommentSeen(fileRequest),
                        content: <div>
                            {fileRequest.comments.length === 0 && <p>No comments</p>}
                            {fileRequest.comments.map(comment => {
                                return <div key={comment.id} className="comment">
                                    <strong>{comment.userName} @ {DateUtils.dateToFriendly(comment.commentedAt)}</strong>
                                    <p>{comment.comment}</p>
                                </div>
                            })}
                            <div className="comment">
                                <form onSubmit={this.postFileRequestComment(fileRequest)}>
                                    <TextInput type="multiline-text" title="New Comment" disabled={fileRequest.postingComment} value={fileRequest.newComment} onChange={this.setStateFileRequest(fileRequest.id, "newComment")} />
                                    <SubmitButton errorMessage={fileRequest.postCommentError} title="Post Comment" disabled={fileRequest.postingComment} />
                                </form>
                            </div>
                        </div>
                    },]} />
                </ExpandyBoi>
            })}
        </>
    }
}
