import * as React from "react";

export default class TextInput extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            active: false
        };
        
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onFocus() {
        this.setState({
            active: true
        });
    }
    
    onBlur() {
        this.setState({
            active: false
        });
    }
    
    onChange(e) {
        if (this.props.onChange) {
            if (this.props.type === "number") {
                if (isNaN(e.target.value)) {
                    const parsed = parseInt(e.target.value.substring(0, e.target.value.length - 1))
                    this.props.onChange(isNaN(parsed) ? 0 : parsed);
                    return
                }

                const parsed = parseInt(e.target.value)
                this.props.onChange(parsed);

                return
            }
            
            this.props.onChange(e.target.value);
        }
    }
    
    render() {
        if (this.props.type === "text" || this.props.type === "password" || this.props.type === "number") {
            let htmlType = "text"
            if (this.props.type === "password") htmlType = "password"
            
            return (
                <div className="textInputContainer">
                    <span className="textInputLabel">{this.props.title}</span>
                    <input placeholder={this.props.title} type={htmlType} value={this.props.value} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} disabled={this.props.disabled} maxLength={this.props.maxLength} className="textInput" />
                </div>
            );
        } else if (this.props.type === "multiline-text") {
            return (
                <div className="textInputContainer">
                    <span className="textInputLabel">{this.props.title}</span>
                    <textarea value={this.props.value} onFocus={this.onFocus} onBlur={this.onBlur} disabled={this.props.disabled} onChange={this.onChange} className="textInput" />
                </div>
            );
        }
    }
}