class Auth {
    setLoggedIn(authToken, expiresAt, userId, isAdmin, userDetails) {
        localStorage.setItem("auth-token", authToken);
        localStorage.setItem("auth-token-expires", new Date(expiresAt).getTime().toString());
        localStorage.setItem("user-id", userId);
        localStorage.setItem("is-admin", isAdmin);
        localStorage.setItem("user-details", JSON.stringify(userDetails));
    }
    
    isLoggedIn() {
        const authToken = localStorage.getItem("auth-token");
        const authTokenExpires = localStorage.getItem("auth-token-expires");
        const userId = localStorage.getItem("user-id");
        const isAdmin = localStorage.getItem("is-admin");
        
        if (authToken === null || userId === null || authTokenExpires === null || isAdmin === null) {
            return false;
        }
        
        const loggedIn = new Date(parseInt(authTokenExpires)) > new Date();
        
        return loggedIn;
    }
    
    logout() {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("auth-token-expires");
        localStorage.removeItem("user-id");
        localStorage.removeItem("is-admin");
        localStorage.removeItem("user-details");
    }
    
    getAuthToken() {
        const authToken = localStorage.getItem("auth-token");
        const authTokenExpires = localStorage.getItem("auth-token-expires");
        const userId = localStorage.getItem("user-id");
        const isAdmin = localStorage.getItem("is-admin");

        if (authToken === null || userId === null || authTokenExpires === null || isAdmin === null || new Date(parseInt(authTokenExpires)) < new Date()) {
            this.logout();
            return null;
        }
        
        return authToken;
    }

    getAuthTokenIfPossible() {
        const authToken = localStorage.getItem("auth-token");
        const authTokenExpires = localStorage.getItem("auth-token-expires");
        const userId = localStorage.getItem("user-id");
        const isAdmin = localStorage.getItem("is-admin");

        if (authToken === null || userId === null || authTokenExpires === null || isAdmin === null || new Date(parseInt(authTokenExpires)) < new Date()) {
            return null;
        }

        return authToken;
    }
    
    getUserId() {
        return localStorage.getItem("user-id");
    }

    isAdmin() {
        return localStorage.getItem("is-admin") === "true";
    }
    
    getUserDetails() {
        const result = localStorage.getItem("user-details")
        
        if (!result) {
            return null
        }
        
        return JSON.parse(result)
    }
}

export default new Auth();