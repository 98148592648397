export default class DateUtils {
    static dateToFriendly(date) {
        let usableDate = date;
        
        if (!(date instanceof Date)) {
            usableDate = new Date(Date.parse(date))
        }
        
        return this.pad(usableDate.getDate()) + "/" + 
            (this.pad(usableDate.getMonth() + 1)) + "/" + 
            usableDate.getFullYear() + " " + 
            this.pad(usableDate.getHours()) + ":" + 
            this.pad(usableDate.getMinutes())
    }
    
    static pad(value) {
        if (value.toString().length === 1) {
            return "0" + value;
        }
        
        return value;
    }
}