import * as React from "react";

export default class Tabs extends React.Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            currentIndex: 0
        }
    }

    selectIndex = (index) => {
        return () => {
            this.setState({
                currentIndex: index
            })
            
            if (this.props.tabs[index].onSelected) {
                this.props.tabs[index].onSelected()
            }
        }
    }
    
    render() {
        return <div className="tabContainer">
            <div className="tabHeader">
                {this.props.tabs.map((tab, index) => {
                    return <button className={"tabButton" + (this.state.currentIndex === index ? " tabButtonSelected" : "")} key={index} onClick={this.selectIndex(index)}>
                        {tab.title}
                    </button>
                })}
            </div>
            {this.props.tabs.map((tab, index) => {
                return <div key={index} className={"tabBody" + (this.state.currentIndex === index ? " tabBodyVisible" : "")}>
                    {tab.content}
                </div>
            })}
        </div>
    }
}